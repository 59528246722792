import { post } from '@/utils/request';

// 入库统计-获取入库统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.entry/getStatistical',
    data: data
});

// 入库统计-编辑入库统计表
const editStatistical = (data) => post({
    url: '/store/statistical.entry/editStatistical',
    data: data
})


// 入库统计-新增入库统计表
const addStatistical = (data) => post({
    url: '/store/statistical.entry/addStatistical',
    data: data
})

// 入库统计-获取入库统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.entry/getStatisticalList',
    data: data
})

// 入库统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.entry/totalStatisticalData',
    data: data
})

// 入库统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.entry/totalStatisticalRadar',
    data: data
})

// 入库统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.entry/totalStatisticalTime',
    data: data
})

// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.entry/totalStatisticalList',
    data: data
})

const getAttrValueList = (data) => post({
    url: '/store/common.select/getAttrValueList',
    data: data
})

const getMaterialListReq = () => post({
    url: '/store/common.Select/getMaterialList',
});


// 导出
const downloadList = (data) => post({
    url: '/store/statistical.entry/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.entry/deleteStatistical',
    data: data
})


// 数据分析
const totalStatisticalData2D = (data) => post({
    url: '/store/statistical.entry/totalStatisticalData2D',
    data: data
})

// 数据分析导出

const totalStatisticalData2DDownload=(data)=>post({
    url: '/store/statistical.entry/totalStatisticalData2DDownload',
    responseType: 'blob',
    data: data
})


export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    getTotalStatisticalList,
    getAttrValueList,
    downloadList,
    getMaterialListReq,
    deleteStatistical,
    totalStatisticalData2D,
    totalStatisticalData2DDownload

}