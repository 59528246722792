<template>
  <div class="wrapper">
    <div class="content">
      <div class="card">
        <el-page-header
          @back="goBack"
          :content="
            entry_statistical_id === 0 ? '新增入库统计' : '编辑入库统计'
          "
        >
        </el-page-header>
      </div>
    </div>
    <div class="content" style="margin-top: 20px; padding-bottom: 30px">
      <div class="title">
        <div class="span"></div>
        统计条件
      </div>
      <el-form :model="form" ref="form" :rules="rules">
        <div class="form">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item
                label="统计名称"
                :label-width="formLabelWidth"
                prop="name"
              >
                <el-input
                  v-model="form.name"
                  autocomplete="off"
                  placeholder="请输入统计名称"
                  style="width: 80%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="统计类型"
                :label-width="formLabelWidth"
                prop="time_type"
              >
                <el-select
                  placeholder="请选择统计类型"
                  v-model="form.time_type"
                  style="width: 80%"
                >
                  <el-option value="year" label="按年统计"></el-option>
                  <el-option value="month" label="按月统计"></el-option>
                  <el-option value="day" label="按天统计"></el-option>
                  <!-- <el-option value="40" label="自定义时间"></el-option> -->
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="title">
        <div class="span"></div>
        维度设置
      </div>
      <div style="display: flex">
        <div class="set" v-if="dimension.length !== 0">
          <template v-for="(item, index) in dimension">
            <div class="li" :key="index" v-if="item.is_set === 1">
              {{ item.attr_name }}
            </div>
          </template>
        </div>
        <div style="height: 36px; margin-left: 30px">
          <el-button type="primary" plain @click="handleDimension"
            >选择维度</el-button
          >
        </div>
      </div>
      <div class="title">
        <div class="span"></div>
        统计表头设置
      </div>
      <div style="display: flex">
        <div class="set">
          <template v-for="(item, index) in statistical_header">
            <div class="li" :key="index" v-if="item.is_set === 1">
              {{ item.attr_name }}
            </div>
          </template>
        </div>
        <div style="height: 36px; margin-left: 30px">
          <el-button type="primary" plain @click="handlenStatist"
            >选择统计表头</el-button
          >
        </div>
      </div>
      <div class="title">
        <div class="span"></div>
        明细表头设置
      </div>
      <div style="display: flex">
        <div class="set">
          <template v-for="(item, index) in list_header">
            <div class="li" :key="index" v-if="item.is_set === 1">
              {{ item.attr_name }}
            </div>
          </template>
        </div>
        <div style="height: 36px; margin-left: 30px">
          <el-button
            type="primary"
            plain
            @click="
              () => {
                listHeaderVisiable = true;
              }
            "
            >选择明细表头</el-button
          >
        </div>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="submit('form')" :loading="loading"
          >保存</el-button
        >
      </div>
    </div>
    <el-dialog :fullscreen="false" title="设置维度" :visible.sync="visiable">
      <div class="table" style="border-top: 1px solid #e8e8e8">
        <el-table :data="dimension" tooltip-effect="dark" stripe :height="600">
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.is_set"
                :false-label="0"
                :true-label="1"
                :disabled="scope.row.disabled ? true : false"
                @change="handleCheckedDimensionChange"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="attr_name"
            label="维度"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <el-button @click="getStatistical">取消</el-button>
        <el-button
          type="primary"
          @click="
            () => {
              visiable = false;
            }
          "
          >确定</el-button
        >
      </div></el-dialog
    >
    <el-dialog
      :fullscreen="false"
      title="设置统计表头"
      :visible.sync="statistVisiable"
    >
      <div class="table" style="border-top: 1px solid #e8e8e8">
        <el-table :data="statistical_header" stripe :height="600">
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="scope.row.is_set"
                :disabled="scope.row.disabled ? true : false"
                @change="handleCheckedHeaderChange"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="attr_name"
            label="统计表头"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <el-button @click="getStatistical">取消</el-button>
        <el-button
          type="primary"
          @click="
            () => {
              statistVisiable = false;
            }
          "
          >确定</el-button
        >
      </div></el-dialog
    >
    <el-dialog
      :fullscreen="false"
      title="设置表头"
      :visible.sync="listHeaderVisiable"
    >
      <div class="table" style="border-top: 1px solid #e8e8e8">
        <el-table :data="list_header" stripe :height="600">
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-checkbox
                :false-label="0"
                :true-label="1"
                v-model="scope.row.is_set"
                :disabled="scope.row.disabled ? true : false"
                @change="handleCheckedListHeaderChange"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="attr_name"
            label="表头"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <el-button @click="getStatistical">取消</el-button>
        <el-button
          type="primary"
          @click="
            () => {
              listHeaderVisiable = false;
            }
          "
          >确定</el-button
        >
      </div></el-dialog
    >
  </div>
</template>

<script>
import {
  getStatistical,
  addStatistical,
  editStatistical,
} from "@/api/statistics/wareHousing/index.js";
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请完善统计名称",
            trigger: "blur",
          },
        ],
        time_type: [
          {
            required: true,
            message: "请完善统计类型",
            trigger: "change",
          },
        ],
        time_type: [
          { required: true, message: "请完善入库类型", trigger: "change" },
        ],
      },
      formLabelWidth: "130px",
      diaTitle: "维度",
      arr: [],
      visiable: false,
      statistVisiable: false,
      listHeaderVisiable: false,
      dimension: [],
      list_header: [],
      statistical_header: [],
      selectDimension: [],
      selectStatistical_header: [],
      selectList_header: [],
    };
  },
  props: {
    entry_statistical_id: Number,
  },
  created() {
    this.getStatistical();
  },
  methods: {
    /*
     *  获取入库统计默认或设置项
     */
    getStatistical() {
      this.visiable = false;
      this.statistVisiable = false;
      this.listHeaderVisiable = false;
      let data = {};
      if (this.entry_statistical_id !== 0) {
        data.entry_statistical_id = this.entry_statistical_id;
      }
      getStatistical(data).then((res) => {
        if (res.code === 1) {
          this.dimension = res.data.dimension;
          this.list_header = res.data.list_header;
          this.statistical_header = res.data.statistical_header;
          if (res.data.statistical) {
            this.form = {
              ...res.data.statistical,
              time_type: res.data.statistical.conditions.time_type,
              time_type: res.data.statistical.conditions.time_type,
            };
          }
          if (this.dimension.filter((item) => item.is_set === 1).length === 1) {
            const info = this.dimension.find((item) => item.is_set === 1);
            this.dimension = this.dimension.map((item) => {
              if (item.attr_id === info.attr_id) {
                item.disabled = true;
              }
              return item;
            });
          }
          if (
            this.statistical_header.filter((item) => item.is_set === 1)
              .length === 1
          ) {
            const info = this.statistical_header.find(
              (item) => item.is_set === 1
            );
            this.statistical_header = this.statistical_header.map((item) => {
              if (item.attr_id === info.attr_id) {
                item.disabled = true;
              }
              return item;
            });
          }
          if (
            this.list_header.filter((item) => item.is_set === 1).length === 1
          ) {
            const info = this.list_header.find((item) => item.is_set === 1);
            this.list_header = this.list_header.map((item) => {
              if (item.attr_id === info.attr_id) {
                item.disabled = true;
              }
              return item;
            });
          }
        }
      });
    },
    handleCheckedDimensionChange() {
      if (this.dimension.filter((item) => item.is_set === 1).length === 1) {
        const info = this.dimension.find((item) => item.is_set === 1);
        this.dimension = this.dimension.map((item) => {
          if (item.attr_id === info.attr_id) {
            item.disabled = true;
          }
          return item;
        });
      } else {
        this.dimension = this.dimension.map((item) => {
          item.disabled = false;
          return item;
        });
      }
    },
    handleCheckedHeaderChange() {
      if (
        this.statistical_header.filter((item) => item.is_set === 1).length === 1
      ) {
        const info = this.statistical_header.find((item) => item.is_set === 1);
        this.statistical_header = this.statistical_header.map((item) => {
          if (item.attr_id === info.attr_id) {
            item.disabled = true;
          }
          return item;
        });
      } else {
        this.statistical_header = this.statistical_header.map((item) => {
          item.disabled = false;
          return item;
        });
      }
    },
    handleCheckedListHeaderChange() {
      if (this.list_header.filter((item) => item.is_set === 1).length === 1) {
        const info = this.list_header.find((item) => item.is_set === 1);
        this.list_header = this.list_header.map((item) => {
          if (item.attr_id === info.attr_id) {
            item.disabled = true;
          }
          return item;
        });
      } else {
        this.list_header = this.list_header.map((item) => {
          item.disabled = false;
          return item;
        });
      }
    },
    /*
     *    维度
     */
    handleDimension() {
      this.visiable = true;
    },

    /*
     *    维度表头
     */
    handlenStatist() {
      this.statistVisiable = true;
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            ...this.form,
            dimension: this.dimension,
            list_header: this.list_header,
            statistical_header: this.statistical_header,
          };
          this.loading = true;
          if (this.entry_statistical_id === 0) {
            addStatistical(data).then((res) => {
              if (res.code === 1) {
                this.goBack();
              }
            });
          } else {
            data.entry_statistical_id = this.entry_statistical_id;
            editStatistical(data).then((res) => {
              if (res.code === 1) {
                this.loading = false;
                this.goBack();
              } else {
                this.loading = false;
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.form {
  margin-top: 10px;
}
.set {
  max-width: 80%;
  overflow: auto;
  margin: 0 0px 0 30px;
  display: flex;
  border: 1px solid #c4cfdf;
  background: #f5f6fa;
  border-radius: 6px;
  .li {
    white-space: nowrap;
    padding: 0 40px;
    line-height: 36px;
    font-size: 14px;
    color: #565656;
    border-right: 1px solid #c4cfdf;
  }
  .li:last-child {
    border-right: none !important;
  }
}
</style>
